import { AppConfig } from 'src/configuration';
import Turbolinks from 'turbolinks';

// navbar - unread web notifications count badge
const lastCounts = {
  web_notifications: 0,
};

let pageTitle;

function clearNotificationsList(selector) {
  const $el = $(selector);
  $el.removeClass('open');
  $('.dropdown-menu .ajax-content', $el).empty();
}

function dispatchNotificationsTotalCountUpdate(totalCount) {
  document.dispatchEvent(
    new CustomEvent('notifications:total_count:update', {
      bubbles: false,
      cancelable: true,
      detail: {
        totalCount,
      },
    }),
  );
}

function updateDocumentTitle() {
  const chatNotificationCount = window.computedChatsStore?.value?.unreadCounters?.allWithoutMuted;
  const totalCount = chatNotificationCount + lastCounts.web_notifications;

  if (App.notifications_count !== totalCount) {
    App.notifications_count = totalCount;
    dispatchNotificationsTotalCountUpdate(totalCount);
    document.title = App.notifications_count > 0 ? (`(${App.notifications_count}) ${pageTitle}`) : pageTitle;
  }
}

function updateBadgeCount(count) {
  document
    .querySelectorAll('.js-unread-web-notifications-count ef-icon')
    .forEach(element => element.badgeCount = count);
}

function readAllNotifications() {
  lastCounts.web_notifications = AppConfig.user.persistentNotificationsCount;
  updateBadgeCount(lastCounts.web_notifications);

  document.querySelectorAll('.js-notification').forEach((notification) => {
    notification.isRead = true;
  });
}

$(document)
  .on('turbolinks:render', () => {
    updateBadgeCount(lastCounts.web_notifications);
  })

  .on('turbolinks:load', () => {
    pageTitle = document.title.replace(/\(\d+\)\s*/, '');
  })

  .on('sync', (e) => {
    const data = e.detail;
    if (data.unread_web_notifications_count !== lastCounts.web_notifications) {
      updateBadgeCount(data.unread_web_notifications_count);
      clearNotificationsList('.js-web-notifications-dropdown');
    }

    lastCounts.web_notifications = data.unread_web_notifications_count;

    updateDocumentTitle();
  })

  .on('visibility', () => {
    updateDocumentTitle();
  })

  .on('message-global-received', () => {
    updateDocumentTitle();
  })

  .on('show.bs.dropdown', '.notifications-dropdown', async (e) => {
    const self = e.currentTarget;
    if (!self.dataset.notificationsUrl) { return; }
    if (!$('.dropdown-menu .ajax-content', self).html()) {
      self.classList.add('loading');

      const response = await fetch(self.dataset.notificationsUrl, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      });

      if (response.redirected) {
        Turbolinks.visit(response.url);
        return;
      }

      const data = await response.text();

      $('.dropdown-menu .ajax-content', self).html(data);
      self.classList.remove('loading');
    }
  })

  .on('click', '.dropdown-menu.web-notification-menu .message, .web-notification-menu .js-notification-menu', (e) => {
    e.stopPropagation(); // disable dropdown close on message or menu click
  })

  .on('notification-change', (e) => {
    if (e.target.markAsReadOn) lastCounts.web_notifications--;
    else lastCounts.web_notifications++;

    updateBadgeCount(lastCounts.web_notifications);
  })

  .on('notification-delete', (e) => {
    if (e.target.markAsReadOn) {
      lastCounts.web_notifications--;
      updateBadgeCount(lastCounts.web_notifications);
    }
  })
  .on('ajax:beforeSend', '.js-read-all-web-notifications', readAllNotifications)
  .on('notification-all-read', readAllNotifications);
