import I18n from 'i18n';
import { DataBinderMapper } from 'lib/data_binder';
import GroupBinder from './data_binders/group_binder';

const RULES = {
  'js-toggle-favorite': { isFavorite: 'toggle' },
  'js-toggle-contact-person': { isContactPerson: 'toggle' },
  'js-toggle-lock': { isLocked: 'toggle' },
};

// For group members: Toggle contact person
$(document)
  .on('ajax:error ajax:beforeSend', 'bu-member', (e) => {
    GroupBinder(e.currentTarget, DataBinderMapper(e.target, RULES));
  });

// Group list
$(document)
  .on('ajax:error ajax:beforeSend', '.js-group, .js-team', (e) => {
    GroupBinder(e.currentTarget, DataBinderMapper(e.target, RULES));
  });

$(document).on('ajax:success', '.js-toggle-favorite', (e) => {
  GroupBinder(e.currentTarget, { snackMsg: 'favorite' });
});

$(document).on('ajax:success', '.js-toggle-contact-person', (e) => {
  GroupBinder(e.currentTarget, { snackMsg: 'contact' });
});

$(document).on('ajax:success', '.js-toggle-lock', (e) => {
  GroupBinder(e.currentTarget, { snackMsg: 'lock' });
});

// Toggle group info
$(document).on('click', '.js-toggle-unit-info', (e) => {
  e.preventDefault();
  const $parent = $(e.currentTarget).closest('.unit-v2');
  const $this = $(e.currentTarget);

  if (!$parent.length > 0) { return; }

  $parent.toggleClass('current-member');
  $parent.hasClass('current-member') ? $this.text(I18n.t('community.more_info')) : $this.text(I18n.t('community.less_info'));
});
